import { ReactNode, useEffect, useRef, useState } from "react";
import { ChevronDown } from "react-feather";

const Container = ({
  children,
  gray = false,
  full = false,
  className = "",
  innerClassName = "",
  scroll,
  noPadding,
}: {
  children: ReactNode;
  gray?: boolean;
  full?: boolean;
  className?: string;
  innerClassName?: string;
  scroll?: boolean;
  noPadding?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [header, setHeader] = useState(document.querySelector("header"));
  const [headerHeight, setHeaderHeight] = useState(header?.clientHeight || 0);

  useEffect(() => {
    setHeader(document.querySelector("header"));
    header && setHeaderHeight(header?.clientHeight);

    ref && ref.current && setContainerHeight(ref.current?.clientHeight);
  }, [headerHeight, header]);

  const scrollDown = () => {
    window.scroll({
      top: window.scrollY + containerHeight - headerHeight,
      behavior: "smooth",
    });
  };

  return (
    <div
      ref={ref}
      data-aos="fade"
      className={`
        relative py-20 
        ${gray ? "bg-gradient-to-b from-gray-100 to-gray-200" : ""}
        ${full ? "w-full h-screen flex items-center justify-center" : ""}
        ${className}
      `}
    >
      <div
        className={`
        w-full max-w-4xl mx-auto 
        ${noPadding ? "" : "p-4"} 
        ${innerClassName}
      `}
      >
        {children}
        {scroll && (
          <ChevronDown
            onClick={scrollDown}
            className="absolute bottom-12 left-1/2 transform -translate-x-1/2 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default Container;
