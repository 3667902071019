import React, { useState } from "react";
import { ExternalLink, Info } from "react-feather";
import BorderBox from "../components/BorderBox";
import Button from "../components/Button";
import Container from "../components/Container";
import Headline from "../components/Headline";
import { Project, projects } from "../data/projects";

const Projects = () => {
  return (
    <>
      <Container>
        <Headline underline>for others</Headline>

        {projects.map(
          (project, i) =>
            project.category === "customer" && (
              <ProjectItem key={i} project={project} />
            )
        )}
      </Container>
      <Container>
        <Headline underline>for myself</Headline>

        {projects.map(
          (project, i) =>
            project.category === "private" && (
              <ProjectItem key={i} project={project} />
            )
        )}
      </Container>
    </>
  );
};

export default Projects;

const ProjectItem = ({ project }: { project: Project }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="">
      <div className="border-l border-dotted border-dark w-px h-10 my-10 mx-auto" />
      <div className="text-center mb-20">
        <h2 className="text-xl font-NanumGothic mb-4">{project.title}</h2>
        <p className="text-sm">
          {project.type.map((type, i) => {
            const isLast = project.type.length === i + 1;
            return (
              <React.Fragment key={i}>
                <span>{type}</span>
                {!isLast && <span className="text-primary mx-1">{"//"}</span>}
              </React.Fragment>
            );
          })}
        </p>
      </div>
      <div
        onClick={() => setOpen(!open)}
        //onMouseEnter={() => setOpen(true)}
        //onMouseLeave={() => setOpen(false)}
        className="cursor-pointer"
      >
        <BorderBox
          bg="bg-gray-100"
          outerClassName={`${open ? "before:!left-0 before:!top-0" : ""}`}
          innerClassName="p-8 pb-12 mb-4 flex items-center justify-center"
        >
          <img
            src={project.image}
            alt=""
            className={`-mt-20  transition-transform duration-500 ease-in-out ${
              open ? "transform translate-y-10 scale-75" : ""
            }`}
          />
          {!open && (
            <div className="flex items-end justify-center absolute top-0 left-0 right-0 bottom-0">
              <div className="transform translate-y-2.5">
                <Button
                  onClick={() => setOpen(true)}
                  className=" border-l-4 border-t-4 border-white"
                >
                  more
                </Button>
              </div>
            </div>
          )}
          {open && (
            <div
              onClick={() => setOpen(false)}
              className={`cursor-pointer transition-opacity duration-500 bg-white bg-opacity-90 absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center
            ${open ? "opacity-1" : "opacity-0"}
            `}
            >
              <div
                className="inline-flex items-center space-x-4 p-2"
                onClick={(e) => e.stopPropagation()}
              >
                {project.link && (
                  <a href={project.link} target="_blank" rel="noreferrer">
                    <Button className={open && "animate-comeUp"} icon>
                      <span>
                        <ExternalLink className="w-4 h-4" />
                      </span>
                    </Button>
                  </a>
                )}
                <Button className={open && "animate-comeUp"} icon>
                  <span>
                    <Info className="w-4 h-4" />
                  </span>
                </Button>
              </div>
            </div>
          )}
        </BorderBox>
      </div>
      {project.description && (
        <p className="border-l border-dark px-2 pt-6 max-w-[90%] mx-auto -mt-4 text-sm">
          {project.description}
        </p>
      )}
    </div>
  );
};
