import { useState } from "react";
import Container from "../components/Container";
import Headline from "../components/Headline";
import Overlay from "../components/Overlay";
import Slider from "../components/Slider";
import { GalleryPhoto } from "../types";

import Animal0 from "../assets/designs/animals/0.jpg";
import Animal1 from "../assets/designs/animals/1.jpg";
import Animal2 from "../assets/designs/animals/2.jpg";
import Animal3 from "../assets/designs/animals/3.jpg";
import Animal4 from "../assets/designs/animals/4.jpg";
import Animal5 from "../assets/designs/animals/5.jpg";
import Animal6 from "../assets/designs/animals/6.jpg";
import Animal7 from "../assets/designs/animals/7.jpg";

import Website0 from "../assets/designs/websites/0.jpg";
import Website1 from "../assets/designs/websites/1.jpg";
import Website2 from "../assets/designs/websites/2.jpg";
import Website3 from "../assets/designs/websites/3.jpg";
import Website4 from "../assets/designs/websites/4.jpg";
import Website5 from "../assets/designs/websites/5.jpg";
import Website6 from "../assets/designs/websites/6.jpg";
import Website7 from "../assets/designs/websites/7.jpg";
import Website8 from "../assets/designs/websites/8.jpg";

import PhotoAlbum from "react-photo-album";
import Other0 from "../assets/designs/other/0.jpg";
import Other1 from "../assets/designs/other/1.jpg";

const Designs = () => {
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxIndex, setLightBoxIndex] = useState(1);
  const [lightBoxPhotos, setLightBoxPhotos] = useState<GalleryPhoto[]>();

  const animals: GalleryPhoto[] = [
    Animal0,
    Animal1,
    Animal2,
    Animal3,
    Animal4,
    Animal5,
    Animal6,
    Animal7,
  ].map((x, i) => {
    return {
      src: x,
      width: 100,
      height: 100,
    };
  });

  const other: GalleryPhoto[] = [
    {
      src: Other0,
      width: 1190,
      height: 1684,
    },
    {
      src: Other1,
      width: 1190,
      height: 1684,
    },
  ];

  const websites: GalleryPhoto[] = [
    {
      src: Website0,
      width: 1440,
      height: 2420,
    },
    {
      src: Website1,
      width: 1440,
      height: 1024,
    },
    {
      src: Website2,
      width: 1440,
      height: 1024,
    },
    {
      src: Website3,
      width: 1440,
      height: 1024,
    },
    {
      src: Website4,
      width: 1440,
      height: 1024,
    },
    {
      src: Website5,
      width: 1440,
      height: 1024,
    },
    {
      src: Website6,
      width: 1440,
      height: 1024,
    },
    {
      src: Website7,
      width: 1440,
      height: 2291,
    },
    {
      src: Website8,
      width: 1440,
      height: 1024,
    },
  ];

  const openLightBox = (index: number, photos: GalleryPhoto[]) => {
    setLightBoxPhotos(photos);
    setLightBoxIndex(index);
    setLightBoxOpen(true);
  };

  return (
    <>
      <Container className="">
        <Headline underline>animals</Headline>

        <PhotoAlbum
          layout="rows"
          padding={0}
          spacing={2}
          photos={animals}
          onClick={e => {
            openLightBox(e.index, animals);
          }}
        />
      </Container>

      <Container className="bg-dark">
        <Headline underline className="text-white">
          websites
        </Headline>
        <PhotoAlbum
          layout="columns"
          columns={2}
          padding={0}
          spacing={2}
          photos={websites}
          onClick={e => {
            openLightBox(e.index, websites);
          }}
        />
      </Container>

      <Container>
        <Headline underline>other</Headline>
        <PhotoAlbum
          layout="columns"
          columns={2}
          padding={0}
          spacing={2}
          photos={other}
          onClick={e => {
            openLightBox(e.index, other);
          }}
        />
      </Container>

      {lightBoxPhotos && (
        <Overlay
          isOpen={lightBoxOpen}
          setIsOpen={setLightBoxOpen}
          classNameOverlay="h-full"
        >
          <Slider photos={lightBoxPhotos} initialSlide={lightBoxIndex} />
        </Overlay>
      )}
    </>
  );
};

export default Designs;
