import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { ChevronUp } from "react-feather";
import { Route, Routes, useLocation } from "react-router-dom";
import Button from "./components/Button";
import Header from "./components/Header";
import { routes } from "./data/routes";
import Footer from "./components/Footer";

function App() {
  const [showScrollUp, setShowScrollUp] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    window.onscroll = function () {
      window.scrollY > window.innerHeight
        ? setShowScrollUp(true)
        : setShowScrollUp(false);
    };
  });

  const scrollToTop = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <Header />
      <Routes>
        {routes.map(({ url, element: Element }, key) => (
          <Route path={url} element={<Element />} key={key} />
        ))}
      </Routes>
      {showScrollUp && (
        <Button
          className="!fixed bottom-4 right-4 group z-10"
          icon
          outline
          onClick={scrollToTop}
        >
          <ChevronUp className="w-4 h-4 group-hover:transform group-hover:-translate-y-0.5 transition-transform duration-300" />
        </Button>
      )}
      <Footer />
    </>
  );
}

export default App;
