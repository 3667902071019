import { ChevronRight } from "react-feather";
import noise from "../assets/noise.png";
import { LinkButton } from "../components/Button";
import Container from "../components/Container";
import Headline from "../components/Headline";
import { Project, projects } from "../data/projects";

const ProjectsPreview = ({ project }: { project: Project }) => {
  return (
    <div
      data-aos="fade"
      className={`p-8 mt-8 bg-primary bg-repeat relative flex items-center justify-between flex-col space-y-4 
        before:content-[""] before:w-full before:h-full before:absolute before:border before:border-dark before:-top-2 before:-left-2 before:z-[-1]
      `}
      style={{
        backgroundImage: `url('${noise}')`,
      }}
    >
      <img
        src={project.image}
        alt={`Preview ${project.title}`}
        className="-mt-16 max-h-[200px]"
      />
      <Headline level={3} size="sm">
        {project.title}
      </Headline>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <Container full scroll>
        <Container data-aos="fade-up" noPadding className=" font-NanumGothic">
          <h1>Florian Weigelt</h1>
          <div className="bg-primary w-20 h-px my-2" />
          <h2>
            Web Design <span className="text-primary">{"//"}</span> Media Design
          </h2>
        </Container>
      </Container>

      <Container
        gray
        innerClassName="flex flex-col items-center justify-center"
      >
        <Headline underline>projects</Headline>
        <div className="grid md:grid-cols-2 gap-8 pt-8 mb-8">
          {projects.map(
            (project, i) =>
              project.showcase && <ProjectsPreview key={i} project={project} />
          )}
        </div>
        <LinkButton to="/projects">
          <span>more</span>
          <ChevronRight className="w-4 h-4" />
        </LinkButton>
      </Container>
    </>
  );
};

export default Home;
