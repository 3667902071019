import { useEffect, useRef } from "react";
import { Codepen, GitHub, Instagram } from "react-feather";
import { Link } from "react-router-dom";
import { ReactComponent as IconStylish } from "../assets/logo-userstyles.svg";

const Footer = () => {
  const routes = [
    {
      url: "https://instagram.com/design.flo.w",
      icon: Instagram,
    },
    {
      url: "https://codepen.io/flowe",
      icon: Codepen,
    },
    {
      url: "https://github.com/toastii",
      icon: GitHub,
    },
    {
      url: "https://userstyles.org/users/309838",
      icon: IconStylish,
    },
  ];

  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (footerRef && footerRef.current)
      document.body.style.paddingBottom = `${footerRef.current.clientHeight}px`;
  });

  return (
    <footer
      ref={footerRef}
      className="absolute bottom-0 left-0 right-0 p-8 bg-dark text-white px-2 "
    >
      <div className="flex flex-col space-y-4 w-full max-w-4xl mx-auto">
        <div className="flex items-center justify-center">
          {routes.map((route, i) => (
            <a
              href={route.url}
              className="p-4 hover:text-primary transition-colors duration-300"
              key={i}
              target="_blank"
              rel="noreferrer"
            >
              <route.icon className="w-4 h-4" />
            </a>
          ))}
        </div>
        <div className="flex justify-center">
          <Link to="/contact" className="text-sm underline">
            Imprint
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
