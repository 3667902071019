import { createClient } from "pexels";
import { ReactNode, useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from "react-feather";
import PhotoAlbum from "react-photo-album";
import Container from "../components/Container";
import Headline from "../components/Headline";
import Overlay from "../components/Overlay";
import Slider from "../components/Slider";
import { GalleryPhoto, LightboxPhoto, PexelsResponse } from "../types";

const Photos = () => {
  const [galleryPhotos, setGalleryPhotos] = useState<GalleryPhoto[]>();
  const [lightboxPhotos, setLightboxPhotos] = useState<LightboxPhoto[]>();
  const [page, setPage] = useState(1);
  const [maxPages, setMaxPages] = useState(1);
  const maxResults = 10;
  const [lightBoxOpen, setLightBoxOpen] = useState(false);
  const [lightBoxIndex, setLightBoxIndex] = useState(1);

  useEffect(() => {
    const client = createClient(
      "563492ad6f91700001000001c2bd9c02424249609b41c846381b55c2"
    );

    const isResponse = (data: any): data is PexelsResponse => {
      return "media" in data;
    };

    client.collections
      .media({ id: "yqwehd8", per_page: maxResults, page: page })
      .then((media) => {
        if (isResponse(media)) {
          setGalleryPhotos(
            media.media.map((photo) => {
              return {
                src: photo.src.medium,
                height: photo.height,
                width: photo.width,
              } as GalleryPhoto;
            })
          );
          setLightboxPhotos(
            media.media.map((photo) => {
              return {
                src: photo.src.large2x,
                height: photo.height,
                width: photo.width,
              } as LightboxPhoto;
            })
          );
          setMaxPages(Math.ceil(media.total_results / maxResults));
        }
      });
  }, [page]);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, [galleryPhotos]);

  return (
    <>
      <Container className="">
        <Headline underline>photos</Headline>
        <div className="">
          {galleryPhotos && (
            <>
              <PhotoAlbum
                layout="rows"
                padding={0}
                spacing={2}
                photos={galleryPhotos} onClick={e => {
                  setLightBoxOpen(true);
                  setLightBoxIndex(e.index);
                }}
              />

              <div className="flex justify-center">
                <div
                  className="
              inline-flex items-center justify-center mt-4 relative
              before:content-[''] before:absolute before:h-px before:-left-6 before:-right-2 before:-top-px before:bg-gray-500 before:z-[-1]
              after:content-[''] after:absolute after:h-px after:-left-2 after:-right-6 after:-bottom-px after:bg-gray-500 after:z-[-1]
              "
                >
                  <PaginationItem
                    page={page - 1}
                    text={<ChevronLeft className="w-4 h-4" />}
                    setPage={setPage}
                    disabled={page - 1 === 0}
                  />
                  {[...Array(maxPages)].map((x, i) => {
                    const currentPage = i + 1;
                    return (
                      <PaginationItem
                        page={currentPage}
                        setPage={setPage}
                        active={page === currentPage}
                        key={currentPage}
                      />
                    );
                  })}
                  <PaginationItem
                    page={page + 1}
                    text={<ChevronRight className="w-4 h-4" />}
                    setPage={setPage}
                    disabled={page === maxPages}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Container>
      {lightboxPhotos && (
        <Overlay
          isOpen={lightBoxOpen}
          setIsOpen={setLightBoxOpen}
          classNameOverlay="h-full"
        >
          <Slider photos={lightboxPhotos} initialSlide={lightBoxIndex} />
        </Overlay>
      )}
    </>
  );
};

const PaginationItem = ({
  page,
  text = page.toString(),
  setPage,
  active,
  disabled,
}: {
  page: number;
  text?: ReactNode;
  setPage: (page: number) => void;
  active?: boolean;
  disabled?: boolean;
}) => {
  return (
    <button
      className={`w-10 h-10 relative flex items-center justify-center text-sm transition-all duration-500
      ${disabled && !active
          ? "text-gray-400"
          : active
            ? "bg-primary font-bold"
            : "bg-transparent hover:bg-primary"
        }`}
      onClick={() => setPage(page)}
      disabled={disabled}
    >
      <div className={`absolute`}>{text}</div>
    </button>
  );
};

export default Photos;
