import klaviertrioIMG from "../assets/projects/klaviertrio.png";
import francisCostaBrandingIMG from "../assets/projects/francis_soundcloud.png";
import bandCampIMG from "../assets/projects/bandCamp.png";
import naturalScrubsIMG from "../assets/projects/scrubs.png";
import quizarooIMG from "../assets/projects/quizzaroo.png";
import meetAndEatIMG from "../assets/projects/meet-and-eat.png";

type ProjectCategory = "customer" | "private";
export interface Project {
  id: string;
  title: string;
  type: string[];
  image: string;
  description?: string;
  link?: string;
  showcase?: boolean;
  category: ProjectCategory;
}

export const projects: Project[] = [
  {
    id: "klaviertrio",
    title: "Klaviertrio Regensburg",
    //description: "Redesign of the whole website.",
    type: ["Redesign"],
    image: klaviertrioIMG,
    link: "http://klaviertrio-regensburg.de/",
    showcase: true,
    category: "customer",
  },
  {
    id: "francisCostaBranding",
    title: "Francis Costa",
    type: ["Logo", "Soundcloud Branding"],
    image: francisCostaBrandingIMG,
    link: "https://soundcloud.com/djfranciscosta/tracks",
    showcase: true,
    category: "customer",
  },
  {
    id: "bandCamp",
    title: "Band Camp",
    type: ["Audio Player", "Website"],
    image: bandCampIMG,
    link: "http://band-camp.lancamp.de/",
    showcase: true,
    category: "private",
  },
  {
    id: "naturalScrubs",
    title: "Natural Scrubs",
    type: ["Branding", "Website", "Logo"],
    image: naturalScrubsIMG,
    showcase: true,
    category: "customer",
  },
  {
    id: "quizaroo",
    title: "Quizaroo",
    type: ["Quiz-app"],
    image: quizarooIMG,
    category: "private",
    link: "https://quizaroo.lancamp.dev",
  },
  {
    id: "meetandeat",
    title: "Meet & Eat",
    type: ["Restaurant", "Reservation tool"],
    image: meetAndEatIMG,
    category: "private",
    link: "https://meet-and-eat.lancamp.dev",
  },
];
