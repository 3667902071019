import { ReactNode } from "react";
import { Link } from "react-router-dom";

type ButtonColor = "primary" | "border";

interface ButtonProps {
  children: ReactNode;
  color?: ButtonColor;
  onClick?: () => void;
  className?: string;
  outline?: boolean;
  icon?: boolean;
  reducedAnimation?: boolean;
}

interface LinkButtonProps extends ButtonProps {
  to: string;
  external?: boolean;
  className?: string;
  buttonClassName?: string;
}

const Button = ({
  children,
  color = "primary",
  onClick,
  className = "",
  outline = true,
  icon,
  reducedAnimation,
}: ButtonProps) => {
  return (
    <div
      onClick={onClick}
      className={`
        pt-2 pb-3 px-8 inline-flex items-center justify-center space-x-2 cursor-pointer no-underline relative text-sm
        ${color === "primary" ? "bg-primary" : ""}
        ${color === "border" ? "bg-white border border-dark" : ""}
        ${
          outline
            ? "after:content-[''] after:absolute after:-left-1 after:-top-1 after:transition-all after:duration-200 after:w-full after:h-full after:border after:border-dark hover:after:-top-0.5 hover:after:-left-0.5"
            : outline && reducedAnimation
            ? ""
            : ""
        }
        ${icon ? "px-3" : ""}
        ${className}
      `}
    >
      {children}
    </div>
  );
};

export default Button;

export const LinkButton = ({
  to,
  children,
  color,
  external,
  className = "",
  buttonClassName = "",
}: LinkButtonProps) => {
  return (
    <Link
      to={to}
      target={external ? "_blank" : ""}
      rel={external ? "noreferrer" : ""}
      className={className}
    >
      <Button color={color} className={buttonClassName}>
        {children}
      </Button>
    </Link>
  );
};
