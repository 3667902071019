import { ReactNode, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { routes } from "../data/routes";

const HeaderItem = ({
  url,
  children,
}: {
  url: string;
  children: ReactNode;
}) => {
  const location = useLocation();
  const active = location.pathname === url;

  const Comp = active ? "div" : Link;

  return (
    <Comp
      to={url}
      className={`
        text-sm h-full py-1 mb-2 md:m-0 md:py-5 inline-flex items-center relative after:content-[''] after:h-0.5  after:absolute after:bottom-0 after:transtion-all after:duration-300
        ${
          active
            ? "text-primary after:w-full after:bg-primary cursor-default"
            : " after:w-0 after:bg-transparent cursor-pointer"
        }
      `}
    >
      {children}
    </Comp>
  );
};

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [height, setHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const home = location.pathname === "/";

  useEffect(() => {
    window.onscroll = function () {
      setScrollTop(window.scrollY);
    };
  });

  useEffect(() => {
    headerRef && headerRef.current && setHeight(headerRef.current.clientHeight);
  }, []);

  const scrolled = scrollTop > height;

  const LogoContainer = home ? "div" : Link;

  return (
    <header
      ref={headerRef}
      className={`
        fixed top-2 left-2 right-2 transition-all duration-300 z-10 bg-white
        ${scrolled ? "shadow-lg" : ""}
      `}
    >
      <div
        className={`px-2 relative flex items-center justify-between w-full max-w-4xl mx-auto bg-white`}
      >
        <LogoContainer to="/">
          <Logo className="w-11 h-11 my-2 text-primary" />
        </LogoContainer>
        <div className="items-stretch space-x-4 md:space-x-8 hidden md:flex">
          {routes.map(({ url, text }, key) => (
            <HeaderItem url={url} key={key}>
              {text}
            </HeaderItem>
          ))}
        </div>
        <div
          className="cursor-pointer md:hidden"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <div
            className={`
              w-8 h-px bg-dark my-2 relative
              before:content-[''] before:h-px before:w-full before:absolute before:bg-dark before:transition-all
              after:content-[''] after:h-px after:w-full after:absolute after:bg-dark after:transition-all
              ${
                mobileMenuOpen
                  ? "before:top-0 after:top-0"
                  : "before:-top-2 after:top-2"
              }
            `}
          />
        </div>

        <div
          className={`absolute bottom-0 left-0 right-0 px-4 bg-white md:hidden z-[-1] opacity-0 transition-all ease-in-out flex flex-col items-center justify-center shadow-lg
          ${mobileMenuOpen ? "opacity-100 transform translate-y-full" : ""}`}
          onClick={() => setMobileMenuOpen(false)}
        >
          {routes.map(({ url, text }, key) => (
            <HeaderItem url={url} key={key}>
              {text}
            </HeaderItem>
          ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
