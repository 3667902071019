import { ReactNode } from "react";

type Level = 1 | 2 | 3 | 4;
type HeadingTag = "h1" | "h2" | "h3" | "h4";
type Size = "xs" | "sm" | "base" | "lg" | "xl";

const Headline = ({
  children,
  level = 2,
  underline,
  className,
  size = "base",
}: {
  children: ReactNode;
  level?: Level;
  underline?: boolean;
  className?: string;
  size?: Size;
}) => {
  const sizeClass = `text-${size}`;
  const Comp = `h${level}` as HeadingTag;

  return (
    <Comp
      className={`
        pb-4 relative table mx-auto ${sizeClass}
        ${underline ? "mb-20" : "mb-4"}
        ${
          underline
            ? " text-base font-normal font-NanumGothic after:content-[''] after:bg-primary after:w-[60%] after:h-0.5 after:max-w-[300px] after:absolute after:bottom-0 after:left-1/2 after:transform after:-translate-x-1/2"
            : ""
        } 
        ${className || ""}
      `}
    >
      {children}
    </Comp>
  );
};

export default Headline;
