import { ReactNode } from "react";
import { Icon, Mail, MapPin, User } from "react-feather";
import Container from "../components/Container";
import Headline from "../components/Headline";
import Outline from "../components/Outline";

const Contact = () => {
  return (
    <Container className="" >
      <Headline underline>contact</Headline>
      <Outline className="inline-block" position="back">
        <ul className="inline-flex flex-col space-y-1 p-8 bg-primary">
          <ContactItem I={User} className="font-bold">
            Florian Weigelt
          </ContactItem>
          <ContactItem I={MapPin}>Bavaria</ContactItem>
          <ContactItem I={Mail}>
            <a href="mailto:contact@florian-weigelt.com" className=" underline">
              contact@florian-weigelt.com
            </a>
          </ContactItem>
        </ul>
      </Outline>
    </Container>
  );
};

const ContactItem = ({
  children,
  className = "",
  I,
}: {
  children: string | ReactNode;
  className?: string;
  I: Icon;
}) => {
  return (
    <li className={`flex items-center space-x-2 ${className}`}>
      <I className="w-4 h-4" />
      {typeof children === "string" ? <span>{children}</span> : children}
    </li>
  );
};

export default Contact;
