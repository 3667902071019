import { ReactNode } from "react";

const Outline = ({
  children,
  className = "",
  offset = "2",
  color = "dark",
  position = "front",
}: {
  children: ReactNode;
  className?: string;
  offset?: string;
  color?: string;
  position?: "front" | "back";
}) => {
  const offsetClass = `before:-top-${offset} before:-left-${offset}`;
  const colorClass = `before:border-${color}`;

  return (
    <div
      className={`relative w-content h-content before:content-[''] before:w-full before:h-full before:absolute before:border 
      ${position === "back" ? "before:z-[-1]" : ""} 
      ${colorClass} ${offsetClass} ${className}`}
    >
      {children}
    </div>
  );
};

export default Outline;
