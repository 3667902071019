import { ReactNode } from "react";
import noise from "../assets/noise.png";

const BorderBox = ({
  children,
  bg,
  border = "border-dark",
  outerClassName,
  innerClassName,
}: {
  children: ReactNode;
  bg: string;
  border?: string;
  outerClassName?: string;
  innerClassName?: string;
}) => {
  return (
    <div
      className={` ${bg} bg-repeat relative 
    before:content-[""] before:w-full before:h-full before:absolute before:border before:${border} before:-top-2 before:-left-2 md:before:-top-4 md:before:-left-4 before:transition-all duration-300
    ${outerClassName || ""}
  `}
      style={{
        backgroundImage: `url('${noise}')`,
      }}
    >
      <div className={`z-[2] relative ${innerClassName || ""}`}>{children}</div>
    </div>
  );
};

export default BorderBox;
