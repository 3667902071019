import { ReactNode, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { X } from "react-feather";

const Overlay = ({
  children,
  isOpen,
  setIsOpen,
  classNameOverlay = "",
}: {
  children: ReactNode;
  isOpen: boolean;
  setIsOpen: any;
  classNameOverlay?: string;
}) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "visible";
  }, [isOpen]);

  useEffect(() => {
    document.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        setIsOpen(false);
      }
    });
  });

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-20"
    >
      <div
        className="absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-gray-100 bg-opacity-90 z-[21] cursor-pointer"
        onClick={() => setIsOpen(false)}
      />
      <div
        className="absolute top-0 right-0 p-4 bg-dark z-[23] cursor-pointer group"
        onClick={() => setIsOpen(false)}
      >
        <X className="text-white w-4 h-4 transition-transform duration-300 group-hover:transform group-hover:rotate-180" />
      </div>
      <div
        className={`z-[22] cursor-default relative w-full ${classNameOverlay}`}
      >
        {children}
      </div>
    </Transition>
  );
};

export default Overlay;
