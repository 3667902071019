import Contact from "../pages/Contact";
import Designs from "../pages/Designs";
import Home from "../pages/Home";
import Photos from "../pages/Photos";
import Projects from "../pages/Projects";

interface RoutesInterface {
  url: string;
  text: string;
  element: any;
}

export const routes: RoutesInterface[] = [
  {
    url: "/",
    text: "home",
    element: Home,
  },
  {
    url: "/projects",
    text: "projects",
    element: Projects,
  },
  {
    url: "/designs",
    text: "designs",
    element: Designs,
  },
  {
    url: "/photos",
    text: "photos",
    element: Photos,
  },
  {
    url: "/contact",
    text: "contact",
    element: Contact,
  },
];
