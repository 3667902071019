import { ChevronLeft, ChevronRight } from "react-feather";
import { Keyboard, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/keyboard";
import { Swiper, SwiperSlide } from "swiper/react";
import { GalleryPhoto } from "../types";
import Outline from "./Outline";

const Slider = ({
  photos,
  initialSlide = 1,
}: {
  photos: GalleryPhoto[];
  initialSlide?: number;
}) => {
  return (
    <>
      <Swiper
        className="bg-primary h-full"
        modules={[Pagination, Navigation, Keyboard]}
        navigation={{
          prevEl: ".prev",
          nextEl: ".next",
        }}
        initialSlide={initialSlide}
        loop
        keyboard
        pagination={{
          type: "fraction",
          el: ".pagination",
          renderFraction(currentClass: string, totalClass: string) {
            return `<span class="${currentClass}" ></span><span class="mx-2">of</span><span class="${totalClass}" ></span>`;
          },
        }}
      >
        {photos.map((photo, i) => (
          <SwiperSlide className="flex items-center justify-center" key={i}>
            <Outline position="back">
              <img
                src={photo.src}
                alt=""
                width={photo.width}
                height={photo.height}
                className="max-h-[90vh] max-w-[90vw] w-auto h-auto"
              />
            </Outline>
          </SwiperSlide>
        ))}
      </Swiper>

      <NavigationButton direction="prev" />
      <NavigationButton direction="next" />
      <div
        className="
          pagination bg-primary py-2 px-4 text-sm 
          inline-block fixed bottom-1 left-1/2 transform -translate-x-1/2 z-10
          
          before:content-[''] before:absolute before:h-px before:bg-gray-500 
          before:-left-8 before:top-1/2 before:transform before:-translate-y-1/2 before:w-8

          after:content-[''] after:absolute after:h-px after:bg-gray-500 
          after:-right-8 after:top-1/2 after:transform after:-translate-y-1/2 after:w-8
        "
      >
        <div className="pagination-divider">|</div>
      </div>
    </>
  );
};

const NavigationButton = ({ direction }: { direction: "prev" | "next" }) => {
  return (
    <div
      className={`
        absolute text-dark cursor-pointer top-1/2 transform -translate-y-1/2 z-20 bg-white bg-opacity-80 p-4 transition-all duration-300
        ${direction === "prev" ? "left-0" : "right-0"}
        ${direction === "prev" ? "sm:hover:pl-8" : "sm:hover:pr-8"}
        ${direction}
    `}
    >
      {direction === "prev" ? (
        <ChevronLeft className="w-4 h-4" />
      ) : (
        <ChevronRight className="w-4 h-fu4ll" />
      )}
    </div>
  );
};

export default Slider;
